<script setup lang="ts">
const successQuery = groq`*[_type == "successStories"]{
  heading,
  subtitle,
  pricing,
  date,
  image{
    ...,
    "asset": {
      "_ref": asset._ref,
      "metadata": {
        "lqip": asset->metadata.lqip,
      }
    }
  },
  clientName,
  clientQuote,
  slug,
} | order(date desc)[0...8]`

const sanity = useSanity()
const { data: successStories, error } = await useAsyncData(successQuery, () => sanity.fetch<Sanity.Default.Schema.SuccessStories[]>(successQuery))
// // console.log(successStories.value)

if (error.value) {
  // console.log('Error fetching success stories')
  console.error(error)
}
</script>

<template>
  <UiFader
    v-if="successStories"
    class=""
  >
    <FeaturesCarousel>
      <template #button>
        <UiBtn
          variant="link"
          color="forest"
          size="lg"
          to="/success-stories"
          label="Success Stories"
          class="mt-1 mr-4"
        />
      </template>
      <div
        v-for="story in successStories"
        :key="story?.slug.current"
        class="snap-start lg:last:pr-2.5 magic-padding shrink-0 w-5/6 md:w-auto"
      >
        <SuccessCard
          :success-story="story"
          class="w-full md:w-[400px] lg:w-[500px] h-full"
        />
      </div>
    </FeaturesCarousel>
  </UiFader>
</template>
